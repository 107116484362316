module.exports = [{
      plugin: require('/Users/wojtekjodel/workspace/wojtekjodel/portfolio/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":820,"quality":90,"linkImagesToOriginal":true}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener noreferrer"}},{"resolve":"gatsby-remark-responsive-iframe","options":{}}]},
    },{
      plugin: require('/Users/wojtekjodel/workspace/wojtekjodel/portfolio/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140805741-1"},
    },{
      plugin: require('/Users/wojtekjodel/workspace/wojtekjodel/portfolio/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/wojtekjodel/workspace/wojtekjodel/portfolio/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
